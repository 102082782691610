/** @jsx jsx */

import qs from 'qs';
import { jsx, Styled, Box, Flex } from 'theme-ui';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import { Container } from '../components/ui';
import successSrc from '../images/success.svg';
import errorSrc from '../images/error.svg';

const FALLBACK_TITLE = 'Kard';

const SUCCESS_MESSAGES = {
  imageSrc: successSrc,
  title: 'Email confirmé !',
  description:
    "Merci d'avoir confirmé votre adresse email. Vous pouvez maintenant fermer la fenêtre."
};

const ERROR_MESSAGES = {
  imageSrc: errorSrc,
  title: 'Lien invalide',
  description:
    'Le lien a peut-être expiré, ou vous avez déjà validé votre email.'
};

const getMessages = () => {
  if (typeof window === 'undefined') return null;

  const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const isSuccess = query.success === 'true';

  return isSuccess ? SUCCESS_MESSAGES : ERROR_MESSAGES;
};

const messages = getMessages();

const EmailConfirmation = () => (
  <Layout sticky={true}>
    <HelmetDatoCms>
      <title>{messages ? messages.title : FALLBACK_TITLE}</title>
    </HelmetDatoCms>

    <Container
      sx={{
        pt: [108, 160],
        minHeight: '100vh'
      }}
    >
      <Flex
        sx={{
          my: 3,
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {messages && (
          <Box sx={{ textAlign: 'center', px: 4 }}>
            <Styled.img sx={{ mb: 2 }} src={messages.imageSrc} />
            <Styled.h2 sx={{ mb: 2 }}>{messages.title}</Styled.h2>
            <Styled.p>{messages.description}</Styled.p>
          </Box>
        )}
      </Flex>
    </Container>
  </Layout>
);

export default EmailConfirmation;
